import { CommonActionTypes } from '../../actions/Common';
//import { randomString } from '../../utils/stringHelper';

export const initialApiGetState = {
    // common
    isPause: false,
    isAlertShowTable: false,
    isRefresh: false,
    counter: 0, //for refresh last updated usage
    isLoading: false,
    showContent: false,
    data: [], //default is array
    rawData: [],
    selectedData: [],
    freezeData: [],
    currentPage: 1,
    pageSize: 10,
    totalRow: 0,
    message: '',
    errMsg: '',
    errors: {},
    selectedModuleTab: '',
    isModalOpen: false,
    isExportModalOpen: false,
    //abnormalbet
    //isUpdated: '',
    // comboghosthighexposure
    comboGroupID: 0,
    selectionCombinationID: 0,
    ipAddress: '',
    maxOdds: '',
    // memberbehaviourchange
    selectedDayRanges: [],
    lastUpdated: '',
    deviceDetailIsOpen: false,
    deviceDetailData: '',
    //membergroupingmembercookie
    cookieGroupingID: '',
    sameMemberCookieGroupingList: [],
    //membergroupingdeviceid
    isSecondModalOpen: false,
    groupId: '',
    memberID: '',
    isBadMember: null,
    platform: '',
    // linkedMemberList: [],
    //narraMemberList: [],
};

const apiGetDataReducer = (state = initialApiGetState, action) => {
    switch (action.type) {
        case CommonActionTypes.PRE_GET_LIST:
            return {
                ...state,
                isLoading: true,
                data: [],
                errMsg: '',
                isRefresh: true,
                showContent: false,
            };
        case CommonActionTypes.GET_LIST_SUCCESS:
            return {
                ...state,
                counter: state.counter++,
                isLoading: false,
                showContent: true,
                data: action.data ?? state.data,
                totalRow: action.totalCount,
                currentPage: action.page,
                pageSize: action.pageSize,
                isRefresh: false,
                // memberbehaviourchange
                lastUpdated: action.lastUpdated,
                selectedDayRanges: action.selectedDayRanges,
            };
        case CommonActionTypes.GET_LIST_SUCCESS_WITHRAWDATA:
            return {
                ...state,
                // memberdashboard
                showContent: true,
                data: action.response.data ?? state.data,
                rawData: action.response.rawData ?? state.rawData,
                selectedData: action.response.selectedData ?? state.selectedData,
                selectedDayRanges: action.response.selectedDayRanges ?? state.selectedDayRanges,
                isLoading: false,
            };
        case CommonActionTypes.GET_LIST_FAILED:
            return {
                ...state,
                isLoading: false,
                showContent: false,
                errMsg: action.response === undefined ? '' : action.response,
            };
        case CommonActionTypes.PAGINATION_CHANGED:
            return {
                ...state,
                currentPage: action.page,
                pageSize: action.pageSize,
            };
        case CommonActionTypes.SET_SELECTED_TAB:
            return {
                ...state,
                selectedModuleTab: action.module ?? state.module,
            };
        //set common modal visible
        case CommonActionTypes.OPEN_MODAL:
            return {
                ...state,
                isModalOpen: true,
                totalRow: action?.response?.totalRow ?? state.totalRow,
                currentPage: action?.response?.currentPage ?? state.currentPage,
                pageSize: action?.response?.pageSize ?? state.pageSize,
                //comboghosthighexposure
                comboGroupID: action?.response?.comboGroupID ?? state.comboGroupID,
                selectionCombinationID: action?.response?.selectionCombinationID ?? state.selectionCombinationID,
                ipAddress: action?.response?.ipAddress ?? state.ipAddress,
                maxOdds: action?.response?.maxOdds ?? state.maxOdds,
                //membergroupingmembercookie
                sameMemberCookieGroupingList: action?.response?.sameMemberCookieGroupingList ?? state.sameMemberCookieGroupingList,
                cookieGroupingID: action?.response?.cookieGroupingID ?? state.cookieGroupingID,
                // membergroupingdeviceid
                //linkedMemberList: action?.response?.linkedMemberList ?? state.linkedMemberList,
                message: action?.response?.message ?? state.message,
            };
        case CommonActionTypes.CLOSE_MODAL:
            return {
                ...state,
                isModalOpen: false,
            };
        case CommonActionTypes.OPEN_MODAL_FAILED:
            return {
                ...state,
                isLoading: false,
                errMsg: action.response === undefined ? '' : action.response,
            };
        case CommonActionTypes.OPEN_SECONDMODAL:
            return {
                ...state,
                isSecondModalOpen: true,
                narraMemberList: action?.response?.narraMemberList,
            };
        case CommonActionTypes.CLOSE_SECONDMODAL:
            return {
                ...state,
                isSecondModalOpen: false,
                // membergroupingdeviceid
                groupId: '',
                memberID: '',
                isBadMember: null,
                platform: '',
            };
        case CommonActionTypes.OPEN_EXPORTDIALOGMODAL:
            return {
                ...state,
                isExportModalOpen: true,
            };
        case CommonActionTypes.CLOSE_EXPORTDIALOGMODAL:
            return {
                ...state,
                isExportModalOpen: false,
            };
        case CommonActionTypes.SET_DATA:
            return {
                ...state,
                data: action.response.data,
                //isUpdated: randomString(),
            };
        case CommonActionTypes.RESET_ERRMSG:
            return {
                ...state,
                errMsg: '',
            };
        case CommonActionTypes.SET_ALERTSHOWTABLE:
            return {
                ...state,
                isAlertShowTable: action.response,
            };
        case CommonActionTypes.SET_FREEZEDATALIST:
            return {
                ...state,
                isPause: true,
                freezeData: action.response,
            };
        case CommonActionTypes.SET_SELECTEDDATALIST:
            return {
                ...state,
                selectedData: action.response.data,
            };
        case CommonActionTypes.SET_ALERTPAUSE:
            return {
                ...state,
                isPause: action.response,
            };
        default:
            throw new Error(`Unexpected action ${action.type}`);
    }
};

export default apiGetDataReducer;
