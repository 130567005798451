//#region event
export const BETATTEMPT_EVENT = 'placebetattempt';
export const BETATTEMPT_EVENT_LEVEL_EVENT = 'placebetattemptevent';
export const NOTIFICATION = 'notification';
export const MEMBERPROFILINGASSIGNEMENT = 'memberprofilingassignment';
export const AGB_EVENT = 'agb';
export const ARBER_WAGER_EVENT = 'arberwager';
//#endregion

//#region UIAction
export const UIACTION_NEW = 'New';
export const UIACTION_UPDATEMEMBERACTIONPG = 'UpdateMemberActionPG';
export const UIACTION_UPDATEMEMBERACTIONSP = 'UpdateMemberActionSP';
export const UIACTION_UPDATEWAGERACTION = 'UpdateWagerAction';
//#endregion
