module.exports = {
    NULL: '"NULL"',
    UNKNOWN: 'Unknown',
    COMBO: 'Combo',
    NOCHANGE: 'No Change',
    BLANK: '<Blank>',
    NOACTION: 'No action',
    ALLACTION: 'All action',
    REJECTBET: 'Reject the bet',
    NA: 'N/A',
    OTHERSPORTS: 'Other Sports',
    VS: ' vs ',
    DASH: ' - ',
    SUPERADMIN: 'superadmin',
    YES: 'Yes',
    NO: 'No',
    ADD: 'Add',
    RESEND: 'Resend',
    ALL: 'All',
    SELECT: 'Select',
    MARK: 'Mark',
    MARKED: 'Marked',
    RELEASE: 'Release',
    RELEASED: 'Released',
    VIP_COMPANY: 'VIP Company',
    BAD: 'Bad',
    REGULAR: 'Regular',
    TOP10: 'Top 10',
    TOP10_BAD: 'Top 10 - Bad',
    TOP10_REGULAR: 'Top 10 - Regular',
    TOP10_UNKNOWN: 'Top 10 - Unknown',
    WEB: 'Web', //UI displayName + WagerSourceName db value
    MOBILE: 'Mobile', //UI displayName
    APP: 'App', //UI displayName
    INTERNET: 'Internet', //WagerSourceName db value
    APPS: 'Apps', //WagerSourceName db value
    NOSPORT: 'NO SPORT',
    OTHER: 'Others',

    SUNFLOWER: 'sunflower',
    WELTON: 'welton',
    KZING: 'kzing',
    GB: 'GB',
    MALOFT: 'maloft',

    CURRENCY_RMB: 'RMB',
    CURRENCY_CNY: 'CNY',

    FEATUREOFFNOACTION: 'No Action - Feature Off.',
    NOACTIONFEATUREOFF: 'No action. Feature OFF. (Legacy Only)',
    NOACTIONMEMCATWHITLISTED: 'No action - Member Category Whitelisted.',
    NOACTIONMEMCATWHITLISTEDFORDLL: 'No action. Member category is whitelisted.',
    FANOACTIONFEATUREOFF: 'Feature Off. Intended action is recorded within GB. No actual action is sent to IPIS.',

    NOACTIONREQUIRED: 'No action required.',

    SORT_BY_ASC: 1,
    SORT_BY_DESC: 2,

    SHOW_BY_TABLE: 1,
    SHOW_BY_CHART: 2,

    COMBOGHOST_FULLCOVER: 4,
    COMBOGHOST_FULLCOVERN1: 3,
    COMBOGHOST_HIGHEXPOSURE: 5,

    MEMBERDASHBOARDPERFORMANCE_DAILY: 'Daily',
    MEMBERDASHBOARDPERFORMANCE_MONTHLY: 'Monthly',
    MEMBERDASHBOARDPERFORMANCE_CANCELLEDWAGER: 'CancelledWager',

    INSTANTBET_MARKETLINE: 'MarketLine',
    INSTANTBET_MARKETLINESELECTION: 'MarketLineSelection',

    MEMBER_ACTION: 'MEMBERACTION',
    MEMBER_REMARK: 'REMARKS',
    MEMBER_SETTING_PROFILE: 'MEMBERSETTINGPROFILE',
    SPREAD_GROUP: 'SPREADGROUP',
    MEMBER_CATEGORY: 'MEMBERCATEGORY',
    ISADVISED: 'ISADVISED',
    PROFILE_GROUP: 'PROFILEGROUP',
    NOT_TO_OVERRIDE_BY_SYSTEM: 'NOTTOOVERRIDEBYSYSTEM',

    TOTAL: 'Total',
    SUCCESSCOUNT: 'Success Count',
    FAILCOUNT: 'Fail Count',
    FAILPERCENTAGE: 'Fail %',

    GENERALSETTING_ITSUPPORT_TAB: 'IT Support',
    ITSUPPORT_ROLE: 'Morpheus_IT_Support',

    STATUS_ACTIVE: 'Active',
    STATUSID_ACTIVE: 1,

    MARKET_PREMATCH: 'PM',
    MARKET_RB: 'RB',

    ARCHIVE: 'Archive',
    CURRENT: 'Current',

    TRUE_STR: 'TRUE',
    FALSE_STR: 'FALSE',

    SPORT_SOCCER: 'Soccer',
    SPORT_BASKETBALL: 'Basketball',
    SPORT_BOXING: 'Boxing',
    SPORT_CYBER_SOCCER: 'Cyber Soccer',
    SPORT_CYBER_BASKETBALL: 'Cyber Basketball',

    WAGERTYPE_SINGLE: 'Single',

    BQ: 'BQ',
    MySQL: 'MySQL',

    GB_UPDATEDBY_LATEBET: 'GB_LateBet',
    GB_UPDATEDBY_MEMBERLISTING: 'GB_MemberList',
    GB_UPDATEDBY_MEMBERTAGGING: 'GB_MemberTagging',

    LASTUPDATED_STR: 'Last Updated At: ',
    LASTUPDATED_GBFEATURE_STR: 'Last Updated For GB Features At: ',
    LASTUPDATED_BET_STR: 'Last Updated for Bet Attempt Related Tables At: ',
    REPORTDATEFROM_STR: 'Data from ',
    COOKIEGROUPINGID_PREFIX: 'M',

    MIN_DATETIME: '0001-01-01T00:00:00',

    AGGRID_ROWHEIGHTSIZE: 35,
};
